import axios from "axios";
import { convertUrl } from "../helpers/helper";

const state = {
  profiles: [],
  profilesList: [],
  paginationPages: 1,
  validationErrors: [],
  isLoading: false,
};

const mutations = {
  SET_PROFILES(state, data) {
    state.profiles = data;
  },
  SET_PROFILES_LIST(state, data) {
    state.profilesList = data;
  },

  SET_PAGINATION_PAGES(state, data) {
    state.paginationPages = data;
  },

  SET_VALIDATION_ERRORS(state, data) {
    state.validationErrors = data;
  },
  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  async getProfiles({ commit }, payload) {
    commit("SET_PROFILES", []);
    commit("SET_IS_LOADING", true);
    try {
      let apiUrl = convertUrl("role", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_PROFILES", data);
      } else {
        commit("SET_PROFILES", data?.result);
        commit("SET_PAGINATION_PAGES", data?.pages);
      }
    } catch (error) {
      commit("SET_PROFILES", []);
    }
    commit("SET_IS_LOADING", false);
  },

  async getProfilesList({ commit }, payload) {
    try {
      let apiUrl = convertUrl("role", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_PROFILES_LIST", data);
      }
    } catch (error) {
      commit("SET_PROFILES_LIST", []);
    }
  },

  async getProfilesByApplication({ commit }, payload) {
    try {
      let apiUrl = `application/${payload.application_id}`;      
      let { data } = await axios.get(apiUrl);
      commit("SET_PROFILES_LIST", data.roles ? data.roles : []);
    } catch (error) {
      commit("SET_PROFILES_LIST", []);
    }
  },

  async saveProfile({ dispatch, commit }, payload) {
    try {
      await axios.post("role", payload);
      dispatch("getProfiles", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async updateProfile({ dispatch, commit }, payload) {
    try {
      await axios.put(`role/${payload.id}`, payload);
      dispatch("getProfiles", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async deleteProfile({ dispatch }, id) {
    try {
      await axios.delete(`role/${id}`);
      dispatch("getProfiles", { page: 1 });
    } catch (error) {
      throw new Error("No se pudo realizar está operación");
    }
  },

  cleanValidationErrors({ commit }) {
    commit("SET_VALIDATION_ERRORS", []);
  },

  updateCurrentPage({ commit }, page) {
    commit("SET_PAGINATION_CURRENT_PAGE", page);
  },
};

const getters = {
  getProfiles(state) {
    let items = state.profiles.map((item) => {
      return {
        ...item,
        app_id: item?.app_id?.id,
        app_name: item?.app_id?.name,
      };
    });
    return items;
  },

  getProfilesList(state) {
    return state.profilesList;
  },

  getPaginationPages(state) {
    return state.paginationPages;
  },

  getValidationErrors(state) {
    return state.validationErrors;
  },
};

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default profile;
