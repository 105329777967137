<template>
  <v-snackbar v-model="snackbar" :color="color" right>
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { $EventBus } from "@/main";
export default {
  data: () => ({
    snackbar: false,
    text: "",
    color: "",
  }),
  mounted: function () {
    $EventBus.$on("showSnack", (data = { text: "", color: "green" }) => {
      if (data && data.text != "") {
        this.snackbar = true;
        this.text = data.text;
        this.color = data.color;
      }
    });
  },
};
</script>
