<template>
	<div class="x-navbar-wrapper">
		<div class="x-navbar">
			<div class="x-navbar__left">
				<img class="x-navbar__logo" :src="require('@/assets/images/logo_confipetrol.png')" />
			</div>
			<div class="x-links d-none d-md-flex d-print-flex">
				<template v-for="(item, i) in items">
					<router-link :to="item.to" class="x-link" :key="i">
						<div class="text">
							<v-icon>{{ item.action }}</v-icon>
							<div class="text__title">{{ item.title }}</div>
						</div>
					</router-link>
				</template>
			</div>
			<div class="x-navbar__right">
				<v-menu bottom min-width="200px" rounded offset-y v-if="user">
					<template v-slot:activator="{ on }">
						<v-btn class="hidden-sm-and-down mr-8" icon x-large v-on="on">
							<v-avatar color="warning" size="48">
								<span class="white--text text-h5">{{ getInitialName() }}</span>
							</v-avatar>
						</v-btn>
					</template>
					<v-card>
						<v-list-item-content class="justify-center">
							<div class="mx-auto text-center">
								<v-avatar color="warning">
									<span class="white--text text-h5">{{ getInitialName() }}</span>
								</v-avatar>
								<h3 class="mt-2">{{ user.name }}</h3>
								<p class="text-caption mt-1">{{ user.email_corp }}</p>
								<v-divider class="my-3"></v-divider>
								<div class="ma-1">
									<v-btn
										:to="{ name: 'UserPermissions' }"
										active-class="no-active"
										depressed
										rounded
										text
										link
									>
										Ver Gerencias
									</v-btn>
								</div>
								<v-divider class="my-3"></v-divider>
								<v-btn @click="onLogout()" depressed rounded text> Cerrar sesión </v-btn>
							</div>
						</v-list-item-content>
					</v-card>
				</v-menu>

				<v-btn class="hidden-md-and-up" icon color="primary" large @click="activeDrawer">
					<v-icon>mdi-menu</v-icon>
				</v-btn>
			</div>
		</div>
		<div class="x-navbar-sub">
			<div class="x-navbar-sub-item">
				<template v-for="(item, index) in items">
					<div class="sub-bar" :key="index" v-if="hasSubpage(item.items)">
						<template v-for="(subpage, i) in item.items">
							<router-link :to="subpage.to" class="x-link" :key="i">
								{{ subpage.title }}
							</router-link>
						</template>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Nav',

	props: {
		items: Array,
	},

	computed: {
		user() {
			return this.$store.state.auth.user || null;
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		activeDrawer() {
			this.$emit('switchDrawer');
		},
		hasSubpage(items) {
			let has = false;
			let path = this.$route.path;
			if (items.some((e) => path.includes(e.to))) {
				has = true;
			}
			return has;
		},
		getInitialName() {
			if (this.user?.name && this.user?.last_name_father) {
				const name = this.user?.name;
				const lastName = this.user?.last_name_father;
				return `${name[0]}${lastName[0]}`;
			}
		},
		onLogout() {
			this.$store.dispatch('auth/logout');
			this.$router.push({ name: 'Login', query: { error: 'Sesión terminada.' } });
		},
		initialize() {},
	},
};
</script>
<style lang="scss" scoped>
@import 'index.scss';
</style>
