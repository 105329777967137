import Vue from "vue";
import Vuex from "vuex";
import companies from "./modules/companies";
import managements from "./modules/managements";
import costCenters from "./modules/costcenters";
import applications from "./modules/applications";
import modules from "./modules/modules";
import pages from "./modules/pages";
import activities from "./modules/activities";
import profiles from "./modules/profiles";
import assignments from "./modules/assignments";
import users from "./modules/users";
import peps from "./modules/peps";
import permissions from "./modules/permissions";
import auth from "./modules/auth";
import charges from "./modules/charges";
import logs from "./modules/logs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  mutations: {},

  actions: {},

  modules: {
    companies,
    managements,
    applications,
    modules,
    pages,
    activities,
    profiles,
    assignments,
    users,
    costCenters,
    peps,
    permissions,
    auth,
    charges,
    logs,
  },
});
