import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  theme: {
    themes: {
      light: {
        primary: "#0039A6",
        warning: "#ff7900",
      },
    },
  },
});
