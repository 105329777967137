import axios from 'axios';

const state = {
	status: 'authenticating', // 'authenticated','not-authenticated', 'authenticating'
	user: null,
	token: null,
};

const mutations = {
	LOGIN_USER(state, { user, token }) {
		if (token) {
			localStorage.setItem('token', token);
			state.token = token;
		}

		state.user = user;
		state.status = 'authenticated';
	},

	LOGOUT() {
		state.user = null;
		state.token = null;
		state.status = 'not-authenticated';

		localStorage.removeItem('token');
	},
};

const actions = {
	async setSession({ commit }, data = null){
		await commit('LOGIN_USER', { user: data.email, token: data.token });
		return { ok: true, message: null };
	},
	
	async signInUser({ commit }, user = null) {
		try {
			const { data } = await axios.post('auth/login', user);
			const { email, accessToken } = data;
			const userDetail = { email };

			await commit('LOGIN_USER', { user: userDetail, token: accessToken });
			return { ok: true, message: null };
		} catch (error) {
			return { ok: false, message: error?.response?.data?.message };
		}
	},

	async checkAuthentication({ commit }) {
		const token = localStorage.getItem('token');

		if (!token) {
			commit('LOGOUT');
			return { ok: false, message: 'No hay token' };
		}

		try {
			const { data } = await axios.get('auth/user', { params: { app_id: parseInt(process.env.VUE_APP_ID) } });
			const user = data;
			const token = localStorage.getItem('token');

			commit('LOGIN_USER', { user, token });

			return { ok: true };
		} catch (error) {
			commit('LOGOUT');
			return { ok: false, message: error.response?.data?.message };
		}
	},

	logout({ commit }) {
		commit('LOGOUT');
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
