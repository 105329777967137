import axios from "axios";
import { convertUrl } from "../helpers/helper";

const state = {
  activities: [],
  activitiesList: [],
  assignedActivities: [],
  paginationPages: 1,
  paginationCurrentPage: 1,
  validationErrors: [],
  isLoading: false,
  isLoadingAssigned: false,
};

const mutations = {
  SET_ACTIVITIES(state, data) {
    state.activities = data;
  },

  SET_ACTIVITIES_LIST(state, data) {
    state.activitiesList = data;
  },

  SET_PAGINATION_PAGES(state, data) {
    state.paginationPages = data;
  },

  SET_PAGINATION_CURRENT_PAGE(state, data) {
    state.paginationCurrentPage = data;
  },

  SET_VALIDATION_ERRORS(state, data) {
    state.validationErrors = data;
  },

  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },

  SET_IS_LOADING_ASSIGNED(state, data) {
    state.isLoadingAssigned = data;
  },

  SET_ASSIGNED_ACTIVITIES(state, data) {
    state.assignedActivities = data;
  },
};

const actions = {
  async getActivities({ commit }, payload) {
    commit("SET_ACTIVITIES", []);
    commit("SET_IS_LOADING", true);
    try {
      let apiUrl = convertUrl("activity", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_ACTIVITIES", data);
      } else {
        commit("SET_ACTIVITIES", data?.result);
        commit("SET_PAGINATION_PAGES", data?.pages);
      }
    } catch (error) {
      commit("SET_ACTIVITIES", []);
    }
    commit("SET_IS_LOADING", false);
  },

  async getActivitiesList({ commit }, payload) {
    try {
      let apiUrl = convertUrl("activity", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_ACTIVITIES_LIST", data);
      }
    } catch (error) {
      commit("SET_ACTIVITIES_LIST", []);
    }
  },

  async getActivitiesByApplication({ commit }, payload) {
    try {
      commit("SET_ACTIVITIES", []);
      commit("SET_IS_LOADING", true);
      let apiUrl = convertUrl("application/all/activities/v2", payload);
      let { data } = await axios.get(apiUrl);
      commit("SET_ACTIVITIES", data);
    } catch (error) {
      commit("SET_ACTIVITIES", []);
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async getActivitiesByRole({ commit }, payload) {
    try {
      commit("SET_ASSIGNED_ACTIVITIES", []);
      commit("SET_IS_LOADING_ASSIGNED", true);
      let apiUrl = `/role/all/activities/${payload?.role_id}`;
      let { data } = await axios.get(apiUrl);
      commit("SET_ASSIGNED_ACTIVITIES", data?.activities || []);
    } catch (error) {
      commit("SET_ASSIGNED_ACTIVITIES", []);
    } finally {
      commit("SET_IS_LOADING_ASSIGNED", false);
    }
  },

  async saveActivity({ dispatch, commit }, payload) {
    try {
      await axios.post("activity", payload);
      dispatch("getActivities", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async updateActivity({ dispatch, commit }, payload) {
    try {
      await axios.put(`activity/${payload.id}`, payload);
      dispatch("getActivities", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async deleteActivity({ dispatch }, id) {
    try {
      await axios.delete(`activity/${id}`);
      dispatch("getActivities", { page: 1 });
    } catch (error) {
      throw new Error("No se pudo realizar está operación");
    }
  },

  cleanActivities({ commit }) {
    commit("SET_ACTIVITIES", []);
    commit("SET_ASSIGNED_ACTIVITIES", []);
  },

  cleanValidationErrors({ commit }) {
    commit("SET_VALIDATION_ERRORS", []);
  },

  updateCurrentPage({ commit }, page) {
    commit("SET_PAGINATION_CURRENT_PAGE", page);
  },
};

const getters = {
  getActivities(state) {
    let items = state.activities.map((item) => {
      return {
        ...item,
        module_id: item?.page_id?.module_id?.id,
        module_name: item?.page_id?.module_id?.name,
        app_id: item?.page_id?.module_id?.app_id?.id,
        app_name: item?.page_id?.module_id?.app_id?.name,
        page_id: item?.page_id?.id,
        page_name: item?.page_id?.name,
      };
    });
    return items;
  },

  getActivitiesList(state) {
    return state.activitiesList;
  },

  getAssignedActivities(state) {
    let activities = [];
    let activitiesList = [];
    if (
      state?.assignedActivities &&
      Array.isArray(state?.assignedActivities) &&
      state.assignedActivities.length > 0
    ) {
      activities = state.assignedActivities;
    }
    for (const activity of activities) {
      const page = activity?.page_id;
      const module = page?.module_id;
      const app = module?.app_id;
      activitiesList.push({
        id: activity.id,
        name: `${app?.name} / ${module?.name} / ${page?.name} / ${activity?.name}`,
      });
    }
    return activitiesList;
  },

  getActivitiesForAssignment(state) {
    let activitiesList = [];
    if (Array.isArray(state?.activities) && state.activities.length > 0) {
      activitiesList = state.activities.map((item) => {
        return {
          id: item.act_id,
          name: `${item?.appName} / ${item?.modName} / ${item?.pagName} / ${item?.actName}`,
        };
      });
    }
    return activitiesList;
  },

  getPaginationPages(state) {
    return state.paginationPages;
  },

  getValidationErrors(state) {
    return state.validationErrors;
  },
};

const activity = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default activity;
