function convertUrl(urlBase = "", items = {}) {
  let path = '';
  items = Object.entries(items);
  if (items && Array.isArray(items) && items.length > 0) {
    items.forEach((element) => {
      if (element[1] && element[1] != null && element[1] != "") {
        if (path.length == 0) {
          path += "?";
        } else {
          path += "&";
        }
        path += element[0] + "=" + element[1];
      }
    });
  }
  return urlBase + path;
}

function buildUrlWithParams(urlBase = "", params = {}) {
  const queryParams = [];

  for (const [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined && value !== "") {
      queryParams.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(value)
      );
    }
  }

  if (queryParams.length > 0) {
    return urlBase + "?" + queryParams.join("&");
  } else {
    return urlBase;
  }
}

export { convertUrl, buildUrlWithParams };
