import axios from "axios";

const state = {
  actions: [],
  profiles: [],
};

const mutations = {
  SET_ACTIONS(state, data) {
    state.actions = data;
  },
  SET_PROFILES(state, data) {
    state.profiles = data;
  },
};

const actions = {
  async saveAssignment({ commit }, payload) {
    try {
      await axios.post("role/assign/" + payload.profile_id, payload.activities);
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },
};

const getters = {
  getActions(state) {
    return state.actions;
  },
  getProfiles(state) {
    return state.profiles;
  },
  getAssignmentByIdProfile: (state) => (id) => {
    let profiles = state.profiles;
    let found = profiles.find((profile) => profile.id == id);
    return found ? found.assignments : [];
  },
};

const assignment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default assignment;
