<template>
	<v-app>
		<nav-bar :items="items" @switchDrawer="activeDrawer" />
		<v-main class="b-grey">
			<router-view />
		</v-main>
		<v-navigation-drawer v-model="drawer" fixed temporary>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title v-if="user">{{ user.name }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list dense>
				<v-list-group
					v-for="item in items"
					:key="item.title"
					v-model="item.active"
					:prepend-icon="item.action"
					no-action
				>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
					<v-list-item v-for="child in item.items" :key="child.title" :to="child.to" link>
						<v-list-item-content>
							<v-list-item-title v-text="child.title"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list>
			<template v-slot:append>
				<div class="pa-2">
					<v-btn @click="onLogout()" color="primary" class="text-none" block> Cerrar Sesión </v-btn>
				</div>
			</template>
		</v-navigation-drawer>
		<snack-notification />
	</v-app>
</template>

<script>
import SnackNotification from '../components/global/SnackNotification.vue';
import NavBar from '../components/nav';
export default {
	name: 'DashboardLayout',

	data: () => ({
		drawer: null,
		items: [
			{
				title: 'EMPRESAS',
				action: 'mdi-domain',
				to: '/dashboard/companies',
				items: [
					{
						title: 'Empresas',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/companies/list',
					},
					{
						title: 'Gerencias',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/companies/managements',
					},
					{
						title: 'PEP',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/companies/pep',
					},
					{
						title: 'Centro de Costos',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/companies/cost-centers',
					},
				],
			},
			{
				title: 'APLICACIONES',
				action: 'mdi-database-outline',
				to: '/dashboard/applications',
				items: [
					{
						title: 'Aplicaciones',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/applications/list',
					},
					{
						title: 'Módulos',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/applications/modules',
					},
					{
						title: 'Páginas',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/applications/pages',
					},
					{
						title: 'Actividades',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/applications/activities',
					},
				],
			},
			{
				title: 'PERFILES',
				action: 'mdi-account-multiple-outline',
				to: '/dashboard/profiles',
				items: [
					{
						title: 'Perfiles',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/profiles/list',
					},
					{
						title: 'Asignación',
						action: 'mdi-account-multiple-outline',
						to: '/dashboard/profiles/assignments',
					},
				],
			},
			{
				title: 'USUARIOS',
				action: 'mdi-account-outline',
				to: '/dashboard/users',
				items: [
					{
						title: 'Usuarios',
						action: '',
						to: '/dashboard/users/list',
					},
					{
						title: 'Permisos',
						action: '',
						to: '/dashboard/users/permissions',
					},
					{
						title: 'Cargos',
						action: '',
						to: '/dashboard/users/charges',
					},
				],
			},
			{
				title: 'REPORTE',
				action: 'mdi-file-table-box',
				to: '/dashboard/reports',
				items: [
					{
						title: 'Lista de reportes',
						action: '',
						to: '/dashboard/reports/list',
					},
					// {
					// 	title: 'Permisos',
					// 	action: '',
					// 	to: '/dashboard/users/permissions',
					// },
					// {
					// 	title: 'Cargos',
					// 	action: '',
					// 	to: '/dashboard/users/charges',
					// },
				],
			},
		],
	}),
  computed: {
    user() {
      return this.$store.state.auth.user || null;
    },
  },
	methods: {
		activeDrawer() {
			this.drawer = true;
		},
		onLogout() {
			this.$store.dispatch('auth/logout');
			this.$router.push({ name: 'Login' });
		},
	},
	components: {
		NavBar,
		SnackNotification,
	},
};
</script>
