import axios from "axios";
import { convertUrl } from "../helpers/helper";

const state = {
  management: {},
  managements: [],
  managementsList: [],
  paginationPages: 0,
  paginationCurrentPage: 1,
  validationErrors: [],
  isLoading: false,
};

const mutations = {
  SET_MANAGEMENT(state, data) {
    state.management = data;
  },

  SET_MANAGEMENTS(state, data) {
    state.managements = data;
  },

  SET_MANAGEMENTS_LIST(state, data) {
    state.managementsList = data;
  },

  SET_PAGINATION_PAGES(state, data) {
    state.paginationPages = data;
  },

  SET_PAGINATION_CURRENT_PAGE(state, data) {
    state.paginationCurrentPage = data;
  },

  SET_VALIDATION_ERRORS(state, data) {
    state.validationErrors = data;
  },

  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  async getManagement({ commit }, id) {
    try {
      commit("SET_MANAGEMENT", {});
      commit("SET_IS_LOADING", true);
      const apiUrl = `management/${id}`;
      let { data } = await axios.get(apiUrl);
      commit("SET_MANAGEMENT", data);
    } catch (error) {
      commit("SET_MANAGEMENT", {});
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async getManagements({ commit }, payload) {
    commit("SET_MANAGEMENTS", []);
    commit("SET_IS_LOADING", true);
    try {
      const apiUrl = convertUrl("management", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_MANAGEMENTS", data);
      } else {
        commit("SET_MANAGEMENTS", data?.result);
        commit("SET_PAGINATION_PAGES", data?.pages);
      }
    } catch (error) {
      commit("SET_MANAGEMENTS", []);
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async getManagementsList({ commit }, payload) {
    try {
      let apiUrl = convertUrl("management", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_MANAGEMENTS_LIST", data);
      }
    } catch (error) {
      commit("SET_MANAGEMENTS_LIST", []);
    }
  },

  async saveManagement({ dispatch, commit }, payload) {
    try {
      await axios.post("management", payload);
      dispatch("getManagements", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async updateManagement({ dispatch, commit }, payload) {
    try {
      await axios.put(`management/${payload.id}`, payload);
      dispatch("getManagements", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async deleteManagement({ dispatch }, id) {
    try {
      await axios.delete(`management/${id}`);
      dispatch("getManagements", { page: 1 });
    } catch (error) {
      throw new Error("No se pudo realizar está operación");
    }
  },

  async getManagementByUser({ commit }, userId) {
		try {
			const { data } = await axios.get(`cost-center/user/${userId}`);
			if (Array.isArray(data?.managements)) {
				commit('SET_MANAGEMENTS', data?.managements);
			}
		} catch (error) {
			commit('SET_MANAGEMENTS', []);
		}
	},

	async cleanManagements({ commit }) {
		commit('SET_MANAGEMENTS', []);
	},

  cleanValidationErrors({ commit }) {
    commit("SET_VALIDATION_ERRORS", []);
  },

  updateCurrentPage({ commit }, page) {
    commit("SET_PAGINATION_CURRENT_PAGE", page);
  },
};

const getters = {
  getManagement(state) {
    let management = state?.management;
    return {
      id: management?.id,
      name: management?.name,
      company_id: management?.company_id?.id,
      responsible_id: management?.responsible_id?.id,
      responsible_name: management?.responsible_id?.fullName,
      active: management?.active,
      management_type: management?.management_type
    };
  },
  getManagements(state) {
    let managementList = state.managements.map((item) => {
      return {
        ...item,
        company_name: item?.company_id?.name,
        company_id: item?.company_id?.id,
      };
    });
    return managementList;
  },
  getManagementsList(state) {
    return state.managementsList;
  },
  getPaginationPages(state) {
    return state.paginationPages;
  },
  getValidationErrors(state) {
    return state.validationErrors;
  },
};

const managements = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default managements;
