import axios from "axios";
import { convertUrl } from "../helpers/helper";

const state = {
  logs: [],
  paginationPages: 1,
  validationErrors: [],
  isLoading: false,
};

const mutations = {
  SET_LOGS(state, data) {
    state.logs = data;
  },

  SET_PAGINATION_PAGES(state, data) {
    state.paginationPages = data;
  },

  SET_VALIDATION_ERRORS(state, data) {
    state.validationErrors = data;
  },

  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  async getLogs({ commit }, payload) {
    commit("SET_LOGS", []);
    commit("SET_IS_LOADING", true);
    try {
      let apiUrl = convertUrl("log", payload);
      let { data } = await axios.get(apiUrl);
      console.log(data);
      if (Array.isArray(data)) {
        commit("SET_LOGS", data);
      } else {
        commit("SET_LOGS", data?.result);
        commit("SET_PAGINATION_PAGES", data?.pagination?.pages || 1);
      }
    } catch (error) {
      commit("SET_LOGS", []);
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async exportLogs() {
    try {
      const response = await axios.get('log/export', {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response || null;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  cleanValidationErrors({ commit }) {
    commit("SET_VALIDATION_ERRORS", []);
  },
};

const getters = {
  getLogs(state) {
    return state.logs;
  },

  getPaginationPages(state) {
    return state.paginationPages;
  },

  getValidationErrors(state) {
    return state.validationErrors;
  },
};

const log = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default log;