import axios from "axios";
import { convertUrl } from "../helpers/helper";

const state = {
  charges: [],
  chargesList: [],
  paginationPages: 1,
  validationErrors: [],
  isLoading: false,
};

const mutations = {
  SET_CHARGES(state, data) {
    state.charges = data;
  },
  SET_CHARGES_LIST(state, data) {
    state.chargesList = data;
  },

  SET_PAGINATION_PAGES(state, data) {
    state.paginationPages = data;
  },

  SET_VALIDATION_ERRORS(state, data) {
    state.validationErrors = data;
  },
  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  async getCharges({ commit }, payload) {
    commit("SET_CHARGES", []);
    commit("SET_IS_LOADING", true);
    try {
      let apiUrl = convertUrl("charge", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_CHARGES", data);
      } else {
        commit("SET_CHARGES", data?.charges);
        commit("SET_PAGINATION_PAGES", data?.pages);
      }
    } catch (error) {
      commit("SET_CHARGES", []);
    }
    commit("SET_IS_LOADING", false);
  },

  async getChargesList({ commit }, payload) {
    commit("SET_CHARGES_LIST", []);
    try {
      let apiUrl = convertUrl("charge", payload);
      let { data } = await axios.get(apiUrl);
      if (data.charges) {
        commit("SET_CHARGES_LIST", data.charges);
      }
    } catch (error) {
      commit("SET_CHARGES_LIST", []);
    }
  },

  async saveCharge({ dispatch, commit }, payload) {
    try {
      await axios.post("charge", payload);
      dispatch("getCharges", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async updateCharge({ dispatch, commit }, payload) {
    try {
      await axios.put(`charge/${payload.id}`, payload);
      dispatch("getCharges", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async deleteCharge({ dispatch }, id) {
    try {
      await axios.delete(`charge/${id}`);
      dispatch("getCharges", { page: 1 });
    } catch (error) {
      throw new Error("No se pudo realizar está operación");
    }
  },

  cleanValidationErrors({ commit }) {
    commit("SET_VALIDATION_ERRORS", []);
  },

  updateCurrentPage({ commit }, page) {
    commit("SET_PAGINATION_CURRENT_PAGE", page);
  },
  
  resetChargesList({commit}){
    commit("SET_CHARGES_LIST", []);
  }
};

const getters = {
  getCharges(state) {
    let items = state.charges.map((item) => {
      return {
        ...item
      };
    });
    return items;
  },

  getChargesList(state) {
    return state.chargesList;
  },

  getPaginationPages(state) {
    return state.paginationPages;
  },

  getValidationErrors(state) {
    return state.validationErrors;
  },
};

const charge = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default charge;
