import axios from "axios";
import { convertUrl } from "../helpers/helper";

const state = {
  peps: [],
  pepsList: [],
  paginationPages: 0,
  paginationCurrentPage: 1,
  validationErrors: [],
  isLoading: false,
};

const mutations = {
  SET_PEPS(state, data) {
    state.peps = data;
  },

  SET_PEPS_LIST(state, data) {
    state.pepsList = data;
  },

  SET_PAGINATION_PAGES(state, data) {
    state.paginationPages = data;
  },

  SET_PAGINATION_CURRENT_PAGE(state, data) {
    state.paginationCurrentPage = data;
  },

  SET_VALIDATION_ERRORS(state, data) {
    state.validationErrors = data;
  },

  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  async getPeps({ commit }, payload) {
    commit("SET_PEPS", []);
    commit("SET_IS_LOADING", true);
    try {
      const apiUrl = convertUrl("pep", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_PEPS", data);
      } else {
        commit("SET_PEPS", data?.result);
        commit("SET_PAGINATION_PAGES", data?.pages);
      }
    } catch (error) {
      commit("SET_PEPS", []);
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async getPepsList({ commit }) {
    try {
      let { data } = await axios.get("pep");
      if (Array.isArray(data)) {
        commit("SET_PEPS_LIST", data);
      }
    } catch (error) {
      commit("SET_PEPS_LIST", []);
    }
  },

  async savePep({ dispatch, commit }, payload) {
    try {
      await axios.post("pep", payload);
      dispatch("getPeps", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async updatePep({ dispatch, commit }, payload) {
    try {
      await axios.put(`pep/${payload.id}`, payload);
      dispatch("getPeps", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async deletePep({ dispatch }, id) {
    try {
      await axios.delete(`pep/${id}`);
      dispatch("getPeps", { page: 1 });
    } catch (error) {
      throw new Error("No se pudo realizar está operación");
    }
  },

  cleanValidationErrors({ commit }) {
    commit("SET_VALIDATION_ERRORS", []);
  },

  updateCurrentPage({ commit }, page) {
    commit("SET_PAGINATION_CURRENT_PAGE", page);
  },
};

const getters = {
  getPeps(state) {
    return state.peps;
  },
  getPepsList(state) {
    return state.pepsList;
  },
  getPaginationPages(state) {
    return state.paginationPages;
  },
  getValidationErrors(state) {
    return state.validationErrors;
  },
};

const peps = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default peps;
