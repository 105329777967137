import axios from "axios";

const state = {
  permission: {},
  permissions: {},
  paginationPages: 1,
  validationErrors: [],
  isLoading: false,
  listUserAccess: [],
  listUserAccessPagination: {
    // page: 1,
    // limit: 10,
    // totalItems: 0,
    // totalPages: 0,
    total: 0,
    page: '0',//0,
    per_page: '20',//20,
    from: 0,
    to: 0,
    pages: 0,
    pagination: true
  },
};

const mutations = {
  SET_PERMISSION(state, data) {
    state.permission = data;
  },

  SET_PERMISSIONS(state, data) {
    state.permissions = data;
  },

  SET_PERMISSIONS_LIST(state, data) {
    state.usersList = data;
  },

  SET_PAGINATION_PAGES(state, data) {
    state.paginationPages = data;
  },

  SET_VALIDATION_ERRORS(state, data) {
    state.validationErrors = data;
  },

  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },

  SET_LIST_USER_ACCESS(state, data) {
    state.listUserAccess = data;
  },

  SET_LIST_USER_ACCESS_PAGINATION(state, payload) {
    state.listUserAccessPagination = { ...state.listUserAccessPagination, ...payload };
  },

};

const actions = {
  async getPermission({ commit }, { permission_id }) {
    commit("SET_PERMISSION", {});
    commit("SET_IS_LOADING", true);
    try {
      if (permission_id) {
        const apiUrl = `user/${permission_id}`;
        const { data } = await axios.get(apiUrl);
        commit("SET_PERMISSION", data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async getPermissionsByUser({ commit }, { user_id, company_id }) {
    commit("SET_PERMISSION", {});
    commit("SET_IS_LOADING", true);
    try {
      if (user_id) {
        const apiUrl = `access/${user_id}`;
        const { data } = await axios.get(apiUrl, { params: { company_id } });
        commit("SET_PERMISSION", data);
      }
    } catch (error) {
      commit("SET_PERMISSION", {});
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async addUser({ commit }, user) {
    commit("SET_PERMISSIONS", [{ ...user }]);
  },

  async getListUserAccess({ commit }, payload) {
    try {
      commit("SET_LIST_USER_ACCESS", []);

      // const apiUrl = `access/list-user-access`;
      const apiUrl = `access/list-accesses`;

      const  {data}  = await axios.get(apiUrl, { params: payload });
      const pagination = {
        total: data?.pagination.total,
        page: data?.pagination.page,
        per_page: data?.pagination.per_page,
        from: data?.pagination.from,
        to: data?.pagination.to,
        pages: data?.pagination.pages,
        pagination: data?.pagination.pagination,
      }

      

      commit("SET_LIST_USER_ACCESS", data?.data || []);
      commit('SET_LIST_USER_ACCESS_PAGINATION', pagination || {});

      return { ok: true, data };

    } catch (error) {
      console.log('aqui')
      return { ok: false, error: error?.response || 'Error en el servicio' };
    }
  },

  async deletePermissions(__, payload) {
    try {
      const apiUrl = `access/delete-accesses`;
      const { data } = await axios.delete(apiUrl, {
        params: { company_id: payload.company_id },
        data: { accessIds: payload.accesIds },
      });
      return { ok: true, data };
    } catch (error) {
      return { ok: false, error: error?.response || 'Error en el servicio' };
    }
  },

  async savePermissions({ commit }, payload) {
    try {
      commit("SET_VALIDATION_ERRORS", []);
      await axios.post('/access', payload);
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  cleanValidationErrors({ commit }) {
    commit("SET_VALIDATION_ERRORS", []);
  },

  updateCurrentPage({ commit }, page) {
    commit("SET_PAGINATION_CURRENT_PAGE", page);
  },

  cleanUserPermissionList({ commit }) {
		commit('SET_LIST_USER_ACCESS', []);

    commit('SET_LIST_USER_ACCESS_PAGINATION', {
        total: 0,
        page: '0',
        per_page: '20',
        // from: data?.pagination.from,
        // to: data?.pagination.to,
        pages: 0,
        // pagination: data?.pagination.pagination,
      });
	},
};

const getters = {
  getPermission(state) {
    return state.permission;
  },

  getAccessPermission(state) {
    let access = [];
    if (state.permission?.access && Array.isArray(state.permission?.access)) {
      access = state.permission.access;
    }
    return access;
  },

  getProfilesPermission(state) {
    let profiles = [];
    if (
      state.permission?.profiles &&
      Array.isArray(state.permission?.profiles)
    ) {
      profiles = state.permission.profiles;
    }
    return profiles;
  },

  getPaginationPages(state) {
    return state.paginationPages;
  },

  getValidationErrors(state) {
    return state.validationErrors;
  },

  getListUserAccess(state) {
    const data = state.listUserAccess;
    return data;
  },
};

const permission = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default permission;
