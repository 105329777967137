import axios from "axios";
import { convertUrl } from "../helpers/helper";

const state = {
  costCenter: {},
  costCenters: [],
  costCentersList: [],
  paginationPages: 0,
  paginationCurrentPage: 1,
  validationErrors: [],
  isLoading: false,
};

const mutations = {
  SET_COST_CENTER(state, data) {
    state.costCenter = data;
  },

  SET_COST_CENTERS(state, data) {
    state.costCenters = data;
  },

  SET_COST_CENTERS_LIST(state, data) {
    state.costCentersList = data;
  },

  SET_PAGINATION_PAGES(state, data) {
    state.paginationPages = data;
  },

  SET_PAGINATION_CURRENT_PAGE(state, data) {
    state.paginationCurrentPage = data;
  },

  SET_VALIDATION_ERRORS(state, data) {
    state.validationErrors = data;
  },

  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  async getCostCenter({ commit }, id) {
    try {
      commit("SET_COST_CENTER", {});
      const apiUrl = "cost-center/" + id;
      let { data } = await axios.get(apiUrl);
      commit("SET_COST_CENTER", data);
    } catch (error) {}
  },

  async getCostCenters({ commit }, payload) {
    commit("SET_COST_CENTERS", []);
    commit("SET_IS_LOADING", true);
    try {
      const apiUrl = convertUrl("cost-center", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_COST_CENTERS", data);
      } else {
        commit("SET_COST_CENTERS", data?.result);
        commit("SET_PAGINATION_PAGES", data?.pages);
      }
    } catch (error) {
      commit("SET_COST_CENTERS", []);
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async getCostCentersList({ commit }, payload) {
    try {
      const apiUrl = convertUrl("cost-center", payload);
      let { data } = await axios.get(apiUrl);

      const costCenters = data.map(d => {
        const constCenterName = d.pep_id ? d.pep_id.pep_description : d.name;
        return {
          id: d.id,
          costCenter: d.costCenter,
          name: constCenterName,
          active: d.active,
          pep: d.pep_id && d.pep_id.code,
          management_id: d.management_id ? d.management_id.id : null
        }
      })

      if (Array.isArray(costCenters)) {
        commit("SET_COST_CENTERS_LIST", costCenters);
      }
    } catch (error) {
      commit("SET_COST_CENTERS_LIST", []);
    }
  },

  async getAvailableCostCenters({ commit }, payload) {
    try {
      const apiUrl = convertUrl("cost-center/available/pep", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_COST_CENTERS_LIST", data);
      }
    } catch (error) {
      commit("SET_COST_CENTERS_LIST", []);
    }
  },

  async saveCostCenter({ commit, dispatch }, payload) {
    try {
      await axios.post("cost-center", payload);
      dispatch("getCostCenters", { page: 1 });
    } catch (error) {
      if (error.response?.status == 404) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);        
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async savePepInCostCenter({ dispatch, commit }, payload) {
    try {
      await axios.post(`cost-center/add-pep`, payload);
      dispatch("getCostCenters", { page: 1 });
    } catch (error) {
      if (error.response?.status == 404) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);     
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async updateCostCenter({ dispatch, commit }, payload) {
    try {
      await axios.put(`cost-center/${payload.id}`, payload);
      dispatch("getCostCenters", { page: 1 });
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async deleteCostCenter({ dispatch }, id) {
    try {
      await axios.delete(`cost-center/${id}`);
      dispatch("getCostCenters", { page: 1 });
    } catch (error) {
      throw new Error("No se pudo realizar está operación");
    }
  },

  cleanValidationErrors({ commit }) {
    commit("SET_VALIDATION_ERRORS", []);
  },

  updateCurrentPage({ commit }, page) {
    commit("SET_PAGINATION_CURRENT_PAGE", page);
  },

  cleanCostCentersList({ commit }) {
    commit("SET_COST_CENTERS_LIST", []);
  },
};

const getters = {
  getCostCenter(state) {
    const costCenter = state?.costCenter;
    return {
      id: costCenter?.id,
      name: costCenter?.name,
      pep_id: costCenter?.pep_id,
      active: costCenter?.active,
      costCenter: costCenter?.costCenter,      
      management_id: costCenter?.management_id?.id,
      responsible_id: costCenter?.responsible_id?.id,
      company_id: costCenter?.management_id?.company_id?.id,
    };
  },

  getCostCenters(state) {
    let costCenterList = state.costCenters.map((item) => {
      return {
        ...item,
        company_name: item?.management_id?.company_id?.name,
        company_id: item?.management_id?.company_id?.id,
        management_name: item?.management_id?.name,
        management_id: item?.management_id?.id,
        pep_code: item?.pep_id?.code,
        pep_id: item?.pep_id?.id,
      };
    });
    return costCenterList;
  },

  getCostCentersList(state) {
    return state.costCentersList;
  },

  getPaginationPages(state) {
    return state.paginationPages;
  },

  getValidationErrors(state) {
    return state.validationErrors;
  },
};

const costCenters = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default costCenters;
