import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import DashboardLayout from "../layouts/DashboardLayout";
import isAuthenticatedGuard from '@/router/auth-guard';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    name: "ToLogin",
  },
  {
    path: "/",
    component: DefaultLayout,
    name: "DefaultLayout",
    children: [
      {
        path: "/login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
      },
    ],
  },
  {
    path: "/",
    component: DashboardLayout,
    name: "Home",
    children: [
      {
        path: "/dashboard/companies",
        name: "Companies",
        redirect: "/dashboard/companies/list",
      },
      {
        path: "/dashboard/companies/list",
        name: "CompaniesList",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "../views/dashboard/companies/list/Companies.vue"
          ),
      },
      {
        path: "/dashboard/companies/managements",
        name: "Managements",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "Managements" */ "../views/dashboard/companies/managements/Managements.vue"
          ),
      },
      {
        path: "/dashboard/companies/pep",
        name: "Pep",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "Pep" */ "../views/dashboard/companies/pep/Pep.vue"
          ),
      },
      {
        path: "/dashboard/companies/cost-centers",
        name: "CostCenters",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "CostCenters" */ "../views/dashboard/companies/costcenters/CostCenters.vue"
          ),
      },
      {
        path: "/dashboard/applications",
        name: "Applications",
        redirect: "/dashboard/applications/list",
      },
      {
        path: "/dashboard/applications/list",
        name: "ListApplications",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "AplicationsList" */ "../views/dashboard/applications/list/Applications.vue"
          ),
      },
      {
        path: "/dashboard/applications/modules",
        name: "ListModules",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "Modules" */ "../views/dashboard/applications/modules/Modules.vue"
          ),
      },
      {
        path: "/dashboard/applications/pages",
        name: "ListPages",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "Pages" */ "../views/dashboard/applications/pages/Pages.vue"
          ),
      },
      {
        path: "/dashboard/applications/activities",
        name: "ListActivities",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "activities" */ "../views/dashboard/applications/activities/Activities.vue"
          ),
      },
      {
        path: "/dashboard/profiles",
        name: "profiles",
        redirect: "/dashboard/profiles/list",
      },
      {
        path: "/dashboard/profiles/list",
        name: "ListProfiles",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "ProfilesList" */ "../views/dashboard/profiles/list/Profiles.vue"
          ),
      },
      {
        path: "/dashboard/profiles/assignments",
        name: "Assignments",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "AssignmentsList" */ "../views/dashboard/profiles/assignments/Assignments.vue"
          ),
      },
      {
        path:"/dashboard/reports",
        name: "reports",
        redirect: "/dashboard/reports/list",
      },
      {
        path:"/dashboard/reports/list",
        name:"ListReports",
        beforeEnter:isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "AssignmentsList" */ "../views/dashboard/reports/list/List.vue"
          )
      },
      {
        path: "/dashboard/users",
        name: "users",
        redirect: "/dashboard/users/list",
      },
      {
        path: "/dashboard/users/list",
        name: "ListUsers",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "UsersList" */ "../views/dashboard/users/list/Users.vue"
          ),
      },
      {
        path: "/dashboard/users/permissions",
        name: "Permissions",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsList" */ "../views/dashboard/users/permissions/Permissions.vue"
          ),
      },
      {
        path: "/dashboard/permissions",
        name: "UserPermissions",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "UserPermissions" */ "@/views/dashboard/user/permissions/permissions.vue"
          ),
      },
      {
        path: "/dashboard/users/permissions/:id",
        name: "PermissionsId",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsListid" */ "../views/dashboard/users/permissions/Permissions.vue"
          ),
      },
      // CHARGES
      {
        path: "/dashboard/users/charges",
        name: "Charges",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "UserCharges" */ "@/views/dashboard/users/charges/Charges.vue"
          ),
      },
      {
        path: "/dashboard/logs",
        name: "Logs",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "LogsList" */ "@/views/dashboard/logs/list/Logs.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
