import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from '@/store';

axios.defaults.baseURL = process?.env?.VUE_APP_API_BASE_URL || "https://seguridad-api.smartrix.pe/";

axios.interceptors.request.use((config) => {
	config.headers = {
		Authorization: `Bearer ${localStorage.getItem('token')}`,
	};

	return config;
});

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		const status = store.state.auth.status
		if (error.response.status === 401) {
			store.dispatch('auth/logout');
			if(status == 'authenticated'){
				window.location.href = '/';
			}			
		}
		return Promise.reject(error);
	}
);

Vue.use(VueAxios, axios);
