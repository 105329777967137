<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "DefaultLayout",

  data: () => ({
  }),
};
</script>
